import { extractKnownSlices } from '~utils/unfurl-helpers';
import { get } from '../utils/get';

const extractHeroData = prismicData => ({
  title: prismicData.page_titile,
  subtitle: prismicData.page_subtitle
});

export default prismicData => ({
  hero: extractHeroData(prismicData),
  seo: {
    seo_title: get(prismicData, 'seo_title', ''),
    seo_description: get(prismicData, 'seo_description', ''),
    seo_keywords: get(prismicData, 'seo_keywords', ''),
    seo_image: get(prismicData, 'seo_image.url', '')
  },
  slices: extractKnownSlices(prismicData, 'body')
});
