import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import Link from '~components/Link';
import Container from '~components/Container';

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '64px',
    lineHeight: '75px',
    marginBottom: '40px'
  },
  subtitle: {
    ontSize: '24px',
    lineHeight: '30px'
  },
  containerSpacing: {
    marginTop: '100px'
  }
});

const PremiumLibrary = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <Container className={classes.containerSpacing}>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {subtitle}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        To see this week&apos;s Premium picks or to print the weekly Parsha pages,{' '}
        <Link to="/premium">click here</Link>.
      </Typography>
    </Container>
  );
};
PremiumLibrary.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default PremiumLibrary;
